<template>
  <div>
    <!-- header -->
    <v-container fluid class="pa-0">
      <v-row no-gutters>
        <v-col cols="12">
          <v-parallax src="/img/bgHeader.webp" :height="$vuetify.breakpoint.xlOnly ? '1200' : height">
            <v-container fill-height fluid>
              <v-row justify="center" no-gutters>
                <v-col cols="12" align="center" lg="8" xl="6">
                  <span :class="[(xsOnly) ? 'text-h4' : 'text-h2', 'font-weight-black d-block']"> YelagiriGuide </span>
                  <span class="d-block font-weight-medium" v-if="xsOnly"> Escape the ordinary </span>
                  <span class="my-1 d-block font-weight-medium" v-else> Pack your bags, your dream vacation is waiting. </span>
                  <!-- <find-in :onParallax="onParallax"></find-in> -->
                </v-col>
              </v-row>
            </v-container>
          </v-parallax>
        </v-col>
      </v-row>
    </v-container>
    <!-- services -->
    <v-card :height="(xsOnly) ? '100%' : height - 55" class="d-flex align-center py-14">
      <v-container class="mt-xl-n12">
        <v-row>
          <v-col
            cols="12" lg="4" v-for="(item, index) in menuItems" :key="index" @click="item.navigateTo && $router.push(item.navigateTo)"
            :class="[xsOnly ? 'booking-card' : '', 'text-center']" style="cursor: pointer"
          >
            <v-hover v-slot="{ hover }">
              <div class="px-10 py-5">
                <v-avatar :color="hover ? 'grey lighten-2' : 'grey darken-3'" :size="xsOnly ? 65 : 60">
                  <v-icon :color="hover ? 'grey darken-3' : 'white'" :size="hover ? 28 : 24"> {{ item.icon }} </v-icon>
                </v-avatar>
                <p class="mt-3 mb-2 text-h6 font-weight-black"> {{ item.title }} </p>
                <p class="px-5 font-weight-medium text-justify"> {{ item.description }} </p>
              </div>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <!-- blogs -->
    <v-container v-if="false">
      <v-row no-gutters>
        <v-col cols="12" align-self="center" class="text-center">
          <p class="font-weight-black text-h4"> Our Blogs </p>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-layout row wrap align-center class="ma-0">
            <v-flex xs12 lg6>
              <v-img src="/img/bgHeader.webp" height="100%" width="100%"></v-img>
            </v-flex>
            <v-flex xs12 lg6>
              <v-layout row wrap class="ma-0">
                <v-flex lg12> col2 row1 </v-flex>
              </v-layout>
              <v-layout row wrap class="ma-0">
                <v-flex lg12> col2 row2 </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data () {
    return {
      height: window.innerHeight,
      menuItems: [{
        icon: 'mdi-bed',
        title: 'Rooms Booking',
        // description: `Don’t call it a hotel, call it a home away from home`,
        description: 'Make your vacation or business trip a success by booking one of our spacious and well-appointed rooms. Reserve yours now!',
        // navigateTo: '/rooms',
      }, {
        icon: 'mdi-food-fork-drink',
        title: 'Order Food',
        // description: 'Giving your hunger a new option by our trendy Food Delivery'
        description: 'Get your favorite meals delivered straight to your door, no cooking required!'
      }, {
        icon: 'mdi-bus-alert',
        title: 'Transport Services',
        description: 'Experience the luxury of our transportation services and arrive at your destination in style.'
      }, {
        icon: 'mdi-campfire',
        title: 'Camping & Trekking Services',
        description: `Escape the city and discover breathtaking landscapes on our camping and trekking adventures.`
      }, {
        icon: 'mdi-ticket',
        title: 'Ticket Booking',
        description: 'Book your tickets online with ease and convenience.'
      }, {
        icon: 'mdi-headset',
        title: 'Help/ Consultation',
        description: 'Expert guidance for your business’s success!. Whisper your dreams to us :)',
        navigateTo: '/enquiry',
      }]
    }
  },
  mounted () {
    // set page height
    window.onresize = () => {
      this.height = window.innerHeight
    }
  }
}
</script>
<style scoped>
  .booking-card:nth-child(odd) {
    border-left: 10px dashed rgb(105, 20, 20);
  }
  .booking-card:nth-child(even) {
    border-right: 10px dashed rgb(5, 80, 5);
  }
</style>
